import { z } from "zod";

import { type Shift, shiftSchema } from "../types";

export enum ShiftState {
  CREATED = "CREATED",
  CLAIMED = "CLAIMED",
  CLOCKED_IN = "CLOCKED_IN",
  ON_BREAK = "ON_BREAK",
  ENDED_BREAK = "ENDED_BREAK",
  SKIPPED_BREAK = "SKIPPED_BREAK",
  CLOCKED_OUT = "CLOCKED_OUT",
  VERIFIED = "VERIFIED",
  MISSING_CLOCK_IN = "MISSING_CLOCK_IN",
  MISSING_CLOCK_OUT = "MISSING_CLOCK_OUT",
  MISSING_SIGNATURE = "MISSING_SIGNATURE",
  REJECTED = "REJECTED",
  UNVERIFIED = "UNVERIFIED",
}

export enum UserLocationState {
  INSIDE_GEOFENCE = "INSIDE_GEOFENCE",
  OUTSIDE_GEOFENCE = "OUTSIDE_GEOFENCE",
  LEFT_GEOFENCE = "LEFT_GEOFENCE",
}

export enum SolveUnpaidBreaksRolloutStage {
  OFF = "off",
  CBH_PAYS_FOR_WORKED_BREAKS = "cbh-pays-for-worked-breaks",
  FACILITIES_PAY_FOR_WORKED_BREAKS = "facilities-pay-for-worked-breaks",
}

export const solveUnpaidBreaksStageWithTargeting = z.object({
  stage: z.nativeEnum(SolveUnpaidBreaksRolloutStage),
  minimalStoreVersion: z.string(),
  minimalOtaVersion: z.string(),
});

export type SolveUnpaidBreaksRolloutStageWithTargeting = z.infer<
  typeof solveUnpaidBreaksStageWithTargeting
>;

export const touchpointIntegrationConfigSchema = z.object({
  asAgencyId: z.number(),
  asAgencyName: z.string(),
  kioskLocation: z.string(),
  isWebhookOn: z.boolean().optional(),
  minVersion: z
    .object({
      ota: z.string(),
      store: z.string(),
    })
    .optional(),
});

export type TouchpointIntegrationConfig = z.infer<typeof touchpointIntegrationConfigSchema>;

export enum HcfTimeclockComplianceRolloutState {
  ENABLED = "enabled",
  DISABLED = "disabled",
}

export const shiftStateSchema = z.object({
  state: z.nativeEnum(ShiftState),
  metadata: z.object({
    userLocationState: z.nativeEnum(UserLocationState),
    isShiftOver: z.boolean(),
    hasShiftStarted: z.boolean(),
    timekeepingLimit: z.string(),
    isAfterTimekeepingLimit: z.boolean(),
    isTimekeepingLocationValidationEnabled: z.boolean(),
    isEarlyClockInEnabled: z.boolean(),
    earlyClockInEnabledInMinutes: z.number().optional(),
    isNfcEnabled: z.boolean(),
    displayTimeSheetSummary: z.boolean(),
    isDigitalSignatureEnabled: z.boolean(),
    isCaliforniaTimeSheetEnabled: z.boolean(),
    isUploadTimeSheetNonIpEnabled: z.boolean(),
    isSignatureSubmission: z.boolean(),
    isTimeSheetEditDisabled: z.boolean(),
    requiresLunchBreak: z.boolean(),
    isInstantPay: z.boolean(),
    solveUnpaidBreaksRolloutStage: z.nativeEnum(SolveUnpaidBreaksRolloutStage),
    solveUnpaidBreaksRolloutStageWithTargetingConfig: solveUnpaidBreaksStageWithTargeting,
    touchpointIntegration: touchpointIntegrationConfigSchema.optional(),
    hcfTimeclockComplianceRollout: z
      .object({
        state: z.enum([
          HcfTimeclockComplianceRolloutState.ENABLED,
          HcfTimeclockComplianceRolloutState.DISABLED,
        ]),
        minimalOtaVersion: z.union([z.number(), z.literal("*")]),
        minimalStoreVersion: z.string(),
        timeclockLocation: z.string().optional(),
      })
      .optional(),
    isLate: z.boolean(),
  }),
});

export type HcfTimeclockComplianceRollout = z.infer<
  typeof shiftStateSchema.shape.metadata.shape.hcfTimeclockComplianceRollout
>;

export type ShiftStateData = z.infer<typeof shiftStateSchema>;
export type ShiftStateMetadata = ShiftStateData["metadata"];

export enum NextShiftState {
  CLOCK_IN = "CLOCK_IN",
  BREAK_START = "BREAK_START",
  BREAK_END = "BREAK_END",
  CLOCK_OUT = "CLOCK_OUT",
  UPLOAD_TIMESHEET = "UPLOAD_TIMESHEET",
}

export type ShiftStateDependencies = Pick<
  Shift,
  | "_id"
  | "start"
  | "end"
  | "isInstantPay"
  | "verified"
  | "deleted"
  | "clockInOut"
  | "lunchInOut"
  | "timecard"
> & {
  facility: {
    id: string;
    tmz: string;
  };
};

export const cancelShiftRequestSchema = z.object({
  shiftId: z.string(),
  reasonType: z.string(),
  reasonDescription: z.string(),
  isNative: z.boolean(),
});
export type CancelShiftRequest = z.infer<typeof cancelShiftRequestSchema>;

export const cancelShiftResponseSchema = z.object({
  success: z.boolean(),
  response: shiftSchema,
  error: z.string().nullable(),
});
export type CancelShiftResponse = z.infer<typeof cancelShiftResponseSchema>;
